import React from "react";
import VisitorCounter from "./VisitorCounter";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">Enter Pandaverse</h1>
      </header>

      <VisitorCounter />
    </div>
  );
}

export default App;
