import React, { useState, useEffect } from "react";

interface VisitorCountResponse {
  count: number;
}

function VisitorCounter() {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    fetch("/.netlify/functions/visitorCounter")
      .then((response) => response.json() as Promise<VisitorCountResponse>)
      .then((data) => {
        setCount(data.count);
      })
      .catch((error) => {
        console.error("Error fetching visitor count:", error);
      });
  }, []);

  if (count === 0) {
    return null;
  }

  return (
    <div className="total-counter">
      <span>{count}</span>
    </div>
  );
}

export default VisitorCounter;
